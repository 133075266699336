import * as redux from 'redux'
import UniversalCookies from 'universal-cookie'
import effects from 'redux-effects'
import fetch from 'redux-effects-fetch'
import multi from 'redux-multi'
import { composeWithDevTools } from 'redux-devtools-extension'

import * as analytics from '@rushplay/analytics'
import * as gtm from '@rushplay/analytics/gtm'
import * as maps from '@rushplay/analytics/maps'
import * as api from '@rushplay/api-client'
import * as casino from '@rushplay/casino'
import * as Notifications from '@rushplay/notifications'
import * as offers from '@rushplay/offers'
import * as processes from '@rushplay/processes'
import * as websockets from '@rushplay/websockets'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import * as limits from '@rushplay/compliance/limits'
import * as gamblingHistory from '@rushplay/compliance/gambling-history'
import * as locks from '@rushplay/compliance/locks'
import * as Triggers from '@rushplay/triggers'

import * as Agent from './agent'
import * as Configuration from './configuration'
import * as Cookies from './cookies'
import * as Constants from './constants'
import * as GameCatalog from './game-catalog'
import * as Http from './http'
import * as Inventory from './inventory'
import * as PickedGames from './picked-games'
import * as Player from './player'
import * as Promotions from './promotions'
import * as Analytics from './analytics'

export function createStore(options = {}) {
  return redux.createStore(
    redux.combineReducers({
      analytics: analytics.reducer,
      api: api.reducer,
      casino: casino.reducer,
      configuration: Configuration.reducer,
      gamblingHistory: gamblingHistory.reducer,
      gameCatalog: GameCatalog.reducer,
      http: Http.reducer,
      inventory: Inventory.reducer,
      jurisdiction: jurisdiction.reducer,
      limits: limits.reducer,
      locks: locks.reducer,
      notifications: Notifications.reducer,
      offers: offers.reducer,
      pickedGames: PickedGames.reducer,
      player: Player.reducer,
      processes: processes.reducer,
      promotions: Promotions.reducer,
      triggers: Triggers.reducer,
    }),
    options.initialState,
    composeWithDevTools({})(
      redux.applyMiddleware(
        multi,
        websockets.middleware({
          bindings: {
            [websockets.LOCKS_CHANGED]: () => locks.fetch(),
            [websockets.SESSION_EXPIRED]: () => {
              // Same action is handled in other places, resulting in removal
              // of player related data in redux store, as well as a couple of
              // other things. Later on we might need to implement invalidating
              // specific data in cache here, but for now
              // we only need to remove session related cookies,
              // which are our fallbacks after we completely
              // removed session related stuff from redux
              return [
                Cookies.remove(
                  Constants.CookieKeys.IS_PHONE_VERIFICATION_REQUIRED
                ),
                Cookies.remove(Constants.CookieKeys.TOKEN),
                Notifications.dismissAll(),
                Notifications.add({
                  message: 'session-expired',
                  level: 'info',
                }),
              ]
            },
            [websockets.SHUFTI_PRO_UPLOAD_FEEDBACK]: data =>
              Notifications.add({
                message: data.validUpload
                  ? `my-identity.status.verification.${data.lastUploadedDocumentType}.success`
                  : `my-identity.status.verification.${data.lastUploadedDocumentType}.failure`,
                level: data.validUpload ? 'success' : 'error',
                variables: {
                  declinedReasons: data.declinedReasons
                    ? `
                    <ul>
                      ${data.declinedReasons.map(
                        reason => `<li>${reason}</li>`
                      )}
                    </ul>
                  `
                    : null,
                },
              }),
          },
        }),
        api.middleware({
          countryCodeSelector: state =>
            Configuration.getCountryCode(state.configuration),
          defaultCacheFor: process.browser
            ? // CLIENT: No caching by default, needs to be set to 1 ms as 0
              // disables caching hence causes requests on hydration
              1
            : // SERVER: Roughly the average time to load page on client
              // completetly. This ensures we reuse cache from server requests,
              // but let client fetch fresh data if needed.
              30000,
          host: state => Configuration.getApiUrl(state.configuration),
          substateSelector: state => state.api,
          tokenSelector: () => {
            // This in theory should be a function that gets Redux state
            // and returns a session token, but since we don't want to keep
            // token in the store anymore, we can't use Herz.Auth.useSession()
            // hook, or any hook, we have to find other way to do it
            if (options.cookies?.get) {
              return options.cookies.get(Constants.CookieKeys.TOKEN)
            } else {
              return new UniversalCookies().get(Constants.CookieKeys.TOKEN)
            }
          },
          onBeforeFailure(response, next) {
            if (response.value?.message === 'session-expired') {
              return [
                Cookies.remove(
                  Constants.CookieKeys.IS_PHONE_VERIFICATION_REQUIRED
                ),
                Cookies.remove(Constants.CookieKeys.TOKEN),
                Notifications.dismissAll(),
                Notifications.add({
                  message: 'session-expired',
                  level: 'info',
                }),
                next(),
              ]
            }

            return next()
          },
        }),
        Agent.middleware,
        effects,
        fetch,
        Cookies.middleware(options.cookies),
        analytics.eventQueuingMiddleware({
          events: [Analytics.gtmEvents, Analytics.mapsEvents],
          queueSelector: state => analytics.getEventsQueue(state.analytics),
          onError: error => {
            if (process.browser && window.onerror) {
              window.onerror(error)
            }
          },
        }),
        gtm.middleware(Analytics.gtmEvents),
        maps.middleware(Analytics.mapsEvents, {
          endpoint: 'https://creatives.heropartners.io/masterpostback.aspx',
          key: 'so9kmdauot7syi2x',
          reportId: '2',
        })
      )
    )
  )
}
